import { Box } from '@mui/material';
import parse from 'html-react-parser';

const Text = (props: any) => {
  return (
    <Box data-testid="textContainer"
      sx={{
        width: props.isMobile ? '80%' : props.textWidth ? props.textWidth : '50%',
        padding: props.customPadding ? props.customPadding : '50px 0px',
        margin: '0 auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: props.data.textAlign }}>
        {props.data.title ? <h1>{parse(props.data.title)}</h1> : null }
        <p>{parse(props.data.mainText)}</p>
      </div>
    </Box>
  );
};

export default Text;
