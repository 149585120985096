import { Box, Button } from '@mui/material';
import '../styles/Forms.scss';
import FormsRadioButton from './core/FormsRadioButton';
import FormsTextField from './core/FormsTextField';
import FormsDropdown from './core/FormsDropdown';
import FormsPhone from './core/FormsPhone';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from '../utils/storage';

const Forms = (props: any) => {
  const navigate = useNavigate();
  const [allValues, setAllValues] = useState({
    firstName: '',
    lastName: '',
    zipCode: '',
    country: '',
    city: '',
    email: '',
    phoneNumber: '',
    organization: '',
    heard: '',
    impact: '',
    preferredContact: 'email',
    help: '',
    deviceUnits: '',
  });

  useEffect(() => {
    //Because of SPA window needs to be fully reloaded for the script to run and detect the form
    if (window.localStorage) {
      if (!localStorage.getItem('firstLoad')) {
        localStorage['firstLoad'] = true;
        window.location.reload();
      } else localStorage.removeItem('firstLoad');
    }
  }, []);

  const changeHandler = (e: any, target: string) => {
    setAllValues({ ...allValues, [target]: e.target.value.toLowerCase() });
  };

  const changeHandlerNum = (e: any, target: string) => {
    setAllValues({ ...allValues, [target]: e.target.value });
  };

  const changeHandlerTel = (e: any, target: string, isValidPhoneNumber: boolean) => {
    const input = e.target as HTMLInputElement;
    const phoneNumber = e.target.value;

    if (isValidPhoneNumber) {
      input.setCustomValidity("");
    } else {
      input.setCustomValidity("Invalid phone number");
    }
    
    setAllValues({ ...allValues, [target]: phoneNumber });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    //handle all onSubmits
    //Can assume that any 'empty' states at this point mean its not needed for this particular form.
    //on submit doesn't get called unless all entries in the form that are required are filled in. Only exception is if they are not required.
    const formSubmission = { date: Date.now(), ...allValues };
    switch (window.location.pathname) {
      case '/contact':
        storage(formSubmission, 'contact-forms');
        navigate('/thankyou-contact');
        break;
      case '/community-host-form':
        storage(formSubmission, 'community-host-forms');
        navigate('/thankyou-community-host-form');
        break;
      case '/become-sponsor-form':
        storage(formSubmission, 'community-host-forms');
        navigate('/thankyou-community-host-form');
        break;
      default:
        storage(formSubmission, 'grant-forms');
        navigate('/thankyou-forms');
        break;
    }
  };

  return (
    <Box sx={{ backgroundColor: props.data.backgroundColor }}>
      <form autoComplete="off" className="container-forms" onSubmit={handleSubmit}>
        {props.data.textFields.map((textField: any, index: number) => {
          switch (textField.component) {
            case 'radio':
              return (
                <FormsRadioButton
                  key={index}
                  textField={textField}
                  changeState={changeHandler}
                ></FormsRadioButton>
              );
            case 'dropdown':
              return (
                <FormsDropdown
                  key={index}
                  textField={textField}
                  stateValue={ allValues[`${textField.id}` as keyof typeof allValues] } 
                  changeState={changeHandlerNum}
                ></FormsDropdown>
              );
            case 'tel':
            return (
              <FormsPhone
                key={index}
                textField={textField}
                stateValue={allValues.phoneNumber}
                changeState={changeHandlerTel}
                isMobile={props.isMobile}
              />
            );
            default:
              return (
                <FormsTextField
                  key={index}
                  textField={textField}
                  changeState={changeHandler}
                  isMobile={props.isMobile}
                ></FormsTextField>
              );
          }
        })}
        <div className="buttonContainer-forms">
          <Button
            size="large"
            sx={{
              backgroundColor: props.data.button.color,
              color: props.data.button.textColor,
              width: props.isMobile ? '100%' : 'auto',
            }}
            className="buttonStyle"
            variant="contained"
            type="submit"
          >
            {props.data.button.text}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default Forms;
