import { Grid } from '@mui/material';

const DualComponentImage = (props: any) => {
  return (
    <Grid
      xs={6}
      item={true}
      order={props.data.textPosition && props.data.textPosition == 'left' ? 2 : 1}
    >
      <img
        src={props.data.backgroundImage}
        alt=""
        width="100%"
        style={{
          objectFit: 'cover',
          height: '100%',
          verticalAlign: 'bottom',
        }}
      ></img>
    </Grid>
  );
};

export default DualComponentImage;
