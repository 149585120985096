import axios from 'axios';

const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME || 'iqair-foundation';
const S3_CREDENTIAL = process.env.REACT_APP_S3_CREDENTIAL || '';
const S3_DATE = process.env.REACT_APP_S3_DATE || '';
const S3_POLICY = process.env.REACT_APP_S3_POLICY || '';
const S3_SIGNATURE = process.env.REACT_APP_S3_SIGNATURE || '';

async function FormsPut(data: any, folderName: string) {

  try {
    const formData = new FormData();

    formData.append('bucket', S3_BUCKET_NAME);
    formData.append('key', `${folderName}/${data.firstName}-${data.date}.json`);
    formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    formData.append('X-Amz-Credential', S3_CREDENTIAL);
    formData.append('X-Amz-Date', S3_DATE);
    formData.append('Policy', S3_POLICY);
    formData.append('X-Amz-Signature', S3_SIGNATURE);
    formData.append('file', new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }));

    await axios.post(`https://s3.amazonaws.com/${S3_BUCKET_NAME}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })

  } catch (err) {
    console.log(err);
  }
}

export default FormsPut;
