import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import '../../styles/ImageButton.scss';

const ImageButton = (props: any) => {
    if( !props.url.includes('http') ) {
      return (
        <Link to={props.url ? props.url : '/'}>
          <Button
            size="large"
            data-testid="menuButton"
            sx={{
              backgroundColor: props.buttonColor,
              color: props.textColor,
              width: props.buttonWidth ? props.buttonWidth : 'auto',
            }}
            className="buttonStyle"
            variant="contained"
          >
            {props.text}
          </Button>
        </Link>
      );
    } else {
      return (
        <Box>
          <a href={props.url}>
            <Button
              size="large"
              data-testid="menuButton"
              sx={{
                backgroundColor: props.buttonColor,
                color: props.textColor,
                width: props.buttonWidth ? props.buttonWidth : 'auto',
              }}
              className="buttonStyle"
              variant="contained"
              >
              {props.text}
            </Button>
          </a>
        </Box>
      );
    } 
  };

export default ImageButton;
