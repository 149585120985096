import { FormControl, InputLabel, NativeSelect, InputBase, styled } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {},
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '15.5px 26px 15.5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      border: '2px solid #1976d2',
    },
  },
}));

const DropdownForm = (props: any) => {
  return (
    <FormControl sx={{ marginTop: '15px', width: props.isMobile ? '100%' : props.textField.width }}>
      <InputLabel sx={{ backgroundColor: 'white' }}>{props.textField.title + (props.textField.required ? "*" : "")}</InputLabel>
      <NativeSelect
        value={props.stateValue}
        onChange={(e) => props.changeState(e, props.textField.id)}
        input={<BootstrapInput />}
        id={props.textField.id}
        name={props.textField.title}
        required={props.textField.required}
      >
        <option></option>
        {props.textField.items.map((item: any) => (
          <option value={item.id} key={item.title}>
            {item.title}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default DropdownForm;
