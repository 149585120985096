import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { FormControl } from "@mui/material";
import { useState } from "react";
import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";

interface PhoneFormProps {
  textField: {
    id: string;
    required: boolean;
    width: string;
  };
  isMobile?: boolean;
  changeState: (e: any, id: string, isValid: boolean) => void;
  stateValue: string;
}

const isValidPhoneNumber = (phoneNumber: string, countryCode: CountryCode): boolean => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

  return !!parsedNumber && parsedNumber.isValid();
};

const PhoneForm = (props: PhoneFormProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("us");

  //Used for AutoFill
  const handlePhoneInput = (e: any) => {
    const phoneNumber = e.target.value;
    const countryCode = selectedCountryCode.toUpperCase() as CountryCode;
    const isValid = isValidPhoneNumber(phoneNumber, countryCode);
    props.changeState(e, props.textField.id, isValid);
  };

  //Using Blur because third-party library creates race condition onChange
  const handlePhoneBlur = (e: any) => {
    const phoneNumber = e.target.value;
    const countryCode = selectedCountryCode.toUpperCase() as CountryCode;
    const isValid = isValidPhoneNumber(phoneNumber, countryCode);
    props.changeState(e, props.textField.id, isValid);
  };

  const handleCountryClick = (e: any) => {
    const target = e.target as HTMLElement;
    const clickedLi = target.closest("li.country[data-country-code]");
    if (clickedLi) {
      const selectedCountryCode = clickedLi.getAttribute("data-country-code");
      if (selectedCountryCode ) {
        setSelectedCountryCode(selectedCountryCode);
      }
    }
  };

  return (
    <FormControl
      sx={{ width: props.isMobile ? "100%" : props.textField.width }}
      margin="normal"
      onClick={handleCountryClick}
    >
      <PhoneInput
        specialLabel="Phone Number *"
        country={selectedCountryCode}
        inputClass="MuiInputBase-input"
        value={props.stateValue}
        inputProps={{
          name: "phoneNumber",
          required: props.textField.required,
          onInput: handlePhoneInput,
          onBlur: handlePhoneBlur,
        }}
      />
    </FormControl>
  );
};

export default PhoneForm;
