import TextOverlay from './core/TextOverlay';
import '../styles/BackgroundImage.scss';

const BackgroundImage = (props: any) => {
  //Background Image consists of an img and a Text overlayed on top of it.
  //Lots of conditionals as there are lots of variations to ths design
  const imageResource = props.isMobile && props.data.backgroundImageMobile ? props.data.backgroundImageMobile : props.data.backgroundImage;
  return (
    <div
      id={props.data.id ? props.data.id: props.data.name}
      style={{
        backgroundImage: 'url(' + imageResource + ')',
        minHeight:
          props.isMobile && props.data.mobileBackgroundHeight
            ? props.data.mobileBackgroundHeight
            : props.data.backgroundHeight
            ? props.data.backgroundHeight
            : '750px',
         ...props.data.style   
      }}
      className={
        'container-backgroundImage ' +
        (props.isMobile && props.data.textPosition != 'top' ? 'center' : props.data.textPosition)
      }
      data-testid="backgroundImageTest"
    >
      {props.data.topLogo && props.data.topLogo.url && (
        <img style={{
          width: props.data.topLogo.width,
          height: props.data.topLogo.height,
        }} src={props.data.topLogo.url} alt={props.data.topLogo.alt} />
      )}
      <TextOverlay data={props.data} isMobile={props.isMobile}></TextOverlay>
    </div>
  );
};

export default BackgroundImage;
