import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const MenuButton = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        style={{ color: 'white' }}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.name}
        <ArrowDropDownIcon></ArrowDropDownIcon>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.items.map((item: any, index: any) => {
          return (
            <Link to={item.ref} key={index} style={{ textDecoration: 'none' }}>
              <MenuItem key={index} onClick={handleClose}>
                {item.id}
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuButton;
