import parse from 'html-react-parser';
import '../../styles/Profile.scss';

const Profile = (props: any) => {
  return (
    <div className="profileContainer" data-testid="profileContainer">
      <h2>{props.data.title && parse(props.data.title)}</h2>
      <img
        src={props.data.directorImg}
        alt={props.data.altImgText}
        style={{
          height: '225px',
          borderRadius: '50%',
          marginTop: "19px"
        }}
      ></img>
      <p>{parse(props.data.directorName)}</p>
      <h3>{parse(props.data.directorTitle)}</h3>
    </div>
  );
};

export default Profile;
