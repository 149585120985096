import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Button } from '@mui/material';
import IQAirLogo from '../../assets/logos/IQAir-Foundation_LOGO-01.svg';
import MenuButton from '../core/MenuButton';
import DrawerNav from './DrawerNav';
import '../../styles/MainNavbar.scss';

export const MainNavbar = (props: any) => {
  return (
    <Grid>
      <AppBar className="appBarStyle">
        <Toolbar className="toolbarStyle">
          {props.isMobile ? (
            <>
              <DrawerNav></DrawerNav>
              <Link to="/">
                <img width="250px" height="28px" src={IQAirLogo} alt="IQAir Foundation Logo"></img>
              </Link>
              <div style={{ width: '40px' }}></div>
            </>
          ) : (
            <>
              <Link to="/">
                <img width="250px" height="28px" src={IQAirLogo} alt="IQAir Foundation Logo"></img>
              </Link>
              {props.data.map((text: any, index: number) =>
                text.id === 'Donate Today' ? (
                  <Link to={text.ref} style={{ textDecoration: 'none' }} key={index}>
                    <Button style={{ color: 'white' }} id="basic-button">
                      {text.title}
                    </Button>
                  </Link>
                ) : (
                  <MenuButton name={text.id} items={text.items} key={index} />
                )
              )}
              <div></div>
            </>
          )}
          {/* <SearchBar isMobile={props.isMobile}></SearchBar> */}
        </Toolbar>
      </AppBar>
    </Grid>
  );
};
