import { Grid, Box } from '@mui/material';
import DualComponentText from './core/DualComponentText';
import DualComponentImage from './core/DualComponentImage';
import '../styles/DualComponent.scss';

const DualComponent = (props: any) => {
  return (
    <Box
      id={props.data.id ? props.data.id: props.data.name}
      className="container-dual"
      data-testid="dualComponentContainer"
      sx={{
        backgroundColor: props.data.backgroundColor,
        backgroundImage:
          props.data.imageOverlay && props.isMobile && props.data.backgroundImageMobile
            ? 'url(' + props.data.backgroundImageMobile + ')'
            : props.data.imageOverlay && props.isMobile
            ? 'url(' + props.data.backgroundImageMobile + ')'
            : '',
      }}
    >
      <Grid container>
        <DualComponentText data={props.data} isMobile={props.isMobile}></DualComponentText>
        {!props.isMobile ? (
          <DualComponentImage data={props.data} isMobile={props.isMobile}></DualComponentImage>
        ) : null}
      </Grid>
    </Box>
  );
};

export default DualComponent;
