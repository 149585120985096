import { TextField } from '@mui/material';

const TextFieldForm = (props: any) => {
  const createInputProp = (type: string) => {
    switch (type) {
      case 'email':
        return {
          inputProps: {
            maxLength: 254,
            type: 'email',
            pattern: '(.+)@(.+){2,}.(.+){2,}',
          },
        };
      case 'tel':
        return {
          inputProps: {
            maxLength: 15,
            type: 'tel',
            pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
          },
          helperText: '(ex: 123-456-7890)',
        };
        case 'zip':
          return {
            inputProps: {
              maxLength: 5,
              type: 'text',
              pattern: '[0-9]{5}',
            },
            helperText: '(ex: 90638)',
          };
      default:
        return {
          inputProps: {
            maxLength: 254,
            type: 'text',
          },
        };
    }
  };

  const textFieldSelection = createInputProp(props.textField.type);
  return (
    <TextField
      id={props.textField.id}
      select={props.textField.itemSelection ? true : false}
      required={props.textField.required}
      margin="normal"
      multiline={props.textField.multiline}
      label={props.textField.title}
      sx={{ width: props.isMobile ? '100%' : props.textField.width }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
        },
      }}
      name={props.textField.id}
      onChange={(e: any) => props.changeState(e, e.target.name)}
      inputProps={textFieldSelection.inputProps}
      helperText={textFieldSelection.helperText}
      variant="outlined"
    ></TextField>
  );
};

export default TextFieldForm;
