import { Box, Grid } from '@mui/material';
import '../styles/Card.scss';
import Text from './core/Text';
import Profile from './core/Profile';

const Card = (props: any) => {
  return (
    <Box
      sx={{ backgroundColor: props.data.backgroundColor }}
      data-testid="cardContainer"
      className="container-card"
    >
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={props.isMobile ? 12 : 5} className="profileGrid">
          <Profile data={props.data} isMobile={props.isMobile}></Profile>
        </Grid>
        <Grid item xs={props.isMobile ? 12 : 7} className="descriptionGrid">
          <div className="descriptionContainer">
            <Text
              data={props.data}
              textWidth="70%"
              isMobile={props.isMobile}
              customPadding="0px"
              hideSecondaryTitle={props.isMobile ? true : false}
            ></Text>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Card;
