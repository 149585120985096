import { Drawer, Box, List, ListItem, Typography, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import data from '../../config/cmsEx.json';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const DrawerNav = () => {
  const toggleDrawer =
    (anchor: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: string) => (
    <Box
      sx={{ width: '100%', margin: '25px' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {data.nav.map((text, index) => (
          <div key={index}>
            <ListItem key={index}>
              {text.id === 'Donate Today' && text.ref ? (
                <Link key={index} to={text.ref} style={{ textDecoration: 'none' }}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{text.id}</Typography>
                    }
                  />
                </Link>
              ) : (
                <ListItemText
                  disableTypography
                  primary={
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{text.id}</Typography>
                  }
                />
              )}
            </ListItem>
            {text.items?.map((text2, index) => (
              <Link key={index} to={text2.ref} style={{ textDecoration: 'none' }}>
                <ListItem button>{text2.id}</ListItem>
              </Link>
            ))}
          </div>
        ))}
      </List>
    </Box>
  );

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  return (
    <>
      <IconButton onClick={toggleDrawer('left', true)}>
        <MenuIcon style={{ fill: 'white' }} />
      </IconButton>
      <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </>
  );
};

export default DrawerNav;
