import { Box, Modal, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Checkbox } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../styles/PrivacyPolicyModel.scss'
import { useCookies } from 'react-cookie';

const PrivacyPolicyModal = (props: any) => {
  const [, setCookie] = useCookies(['user_consent']);
  const [allValues, setAllValues] = useState({
    analyticsCheckbox: false,
    advertisingCheckbox: false,
  });

  const [showCookieSettings, setShowCookieSettings] = useState(false); 
  const [open, setOpen] = useState(props.privacyPolicyExists);
  const [expanded, setExpanded] = useState<string | false>(false);

  const consentTypeToStorageKey : {[unit: string]: string} = {
    advertising: 'ad_storage',
    analytics: 'analytics_storage'
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function handleClickCheckbox(e:any) {
    e.stopPropagation(); //Stop accordion from opening on checkbox click
    setAllValues({ ...allValues, [e.target.id]: e.target.checked }); //setting state so that the values can be passed to set cookies
  }
    
  const changeHandler = (value: any) => {
    setShowCookieSettings(value);
  };

  const handleClose = (e: any) => {
    setOpen(false); //Closing Modal

    const userConsentPreferences : {[unit: string]: boolean} = {
      analytics: e.target.id == 'btnApplyAll' ? true : allValues.analyticsCheckbox,
      advertising: e.target.id == 'btnApplyAll' ? true : allValues.advertisingCheckbox
    }

    const granted = [];
    const denied = [];

    for (const key in userConsentPreferences) {
      if (userConsentPreferences[key]) {
        granted.push(consentTypeToStorageKey[key]);
      } else {
        denied.push(consentTypeToStorageKey[key]);
      }
    }
    const userConsentString = encodeUserConsentPreferences(granted, denied);
    setCookie('user_consent', userConsentString, { path: '/' });
  }

  const encodeUserConsentPreferences = (granted:string[], denied: string[]) => {
    return granted.join(',') + '|' + denied.join(',');
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cookie-preferences"
    >
      <Box className="modalStyle">
        {/* Privacy Policy Introductory Text*/}
        <h2 className="modalTitle">Privacy Settings</h2>
        {props.isMobile && showCookieSettings ? null : (
          <div className="paragraph">
            <p className="inline">
              When you visit our website, we store cookies on your browser to collect information.
              The information collected might relate to you, your preferences or your device, and is
              mostly used to make the site work as you expect it to and to provide a more
              personalized web experience. However, you can choose not to allow certain types of
              cookies, which may impact your experience of the site and the services we are able to
              offer.&nbsp;
            </p>
            <a className="privacy-policy-link" href="/privacy-policy">
              View Privacy Policy
            </a>
          </div>
        )}

        {/* Privacy Policy Popup Buttons */}
        {showCookieSettings ? (
          <div style={{ padding: '15px 0px' }}>
            <p>
              Click on the different category headings to find out more and change our default
              settings according to your preference.
              {props.isMobile && showCookieSettings ? (
                <a
                  className="privacy-policy-link"
                  href="/privacy-policy "
                >
                  View Privacy Policy
                </a>
              ) : null}
            </p>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ content: 'accordionSummary' }}
              >
                <Checkbox
                  defaultChecked
                  disabled
                  id=''
                  color="default"
                />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Stricly Necessary</Typography>
                <Typography className="accordionDescriptionColor">
                  Ensures that the website operates properly
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0px 10px 0px 0px' }}>
                <ul>
                  <li>
                    You cannot opt-out of our Strictly Necessary Cookies as they are deployed in
                    order to ensure the proper functioning of our website (such as prompting the
                    cookie banner and remembering your settings, to log into your account, to
                    redirect you when you log out, etc.).
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                classes={{ content: 'accordionSummary' }}
              >
                <Checkbox onClick={(e) => handleClickCheckbox(e)} id='analyticsCheckbox' color="default" />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Analytics</Typography>
                <Typography className="accordionDescriptionColor">
                  Monitor website traffic and optimize your user experience
                </Typography>{' '}
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li> Evaluate which marketing channels are performing better </li>
                  <li>
                    Analyze aggregated data about usage of the website to understand our customers.
                    Randomized identifiers shared with partners.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                classes={{ content: 'accordionSummary' }}
              >
                <Checkbox onClick={(e) => handleClickCheckbox(e)} id='advertisingCheckbox' color="default" />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Marketing</Typography>
                <Typography className="accordionDescriptionColor">
                  Make online and social advertising more relevant for you
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li> Invite specific customer groups to reconnect with our products later </li>
                  <li>
                    Share data with our advertising and social media partners via their third-party
                    cookies to match your interests
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : null}

        {/* Privacy Policy Popup Buttons */}
        <div className="cookies-btn-container">
          <Button
            size="medium"
            className="textButtonStyle"
            onClick={() => changeHandler(!showCookieSettings)}
          >
            {showCookieSettings ? 'Hide Settings' : 'Show Settings'}
          </Button>
          <div className="button-group">
            {showCookieSettings ? (
              <Button
                size="medium"
                className="textButtonStyle"
                sx={{ marginRight: '10px' }}
                id="btnApplySelection"
                onClick={(e) => handleClose(e)}
                >
                Apply Selection
              </Button>
            ) : null}
            <Button
              size="medium"
              className="buttonStylePrivacy"
              variant="contained"
              id="btnApplyAll"
              onClick={(e) => handleClose(e)}
            >
              Accept All Cookies
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
