import { styled } from '@mui/material/styles';
import { Paper, Grid, Link } from '@mui/material';
import parse from 'html-react-parser';
import '../../styles/FooterNavbar.scss';

const WhiteLink = styled(Link)(() => ({
  color: 'white',
  textDecoration: 'none',
}));

const FooterNavbarDesktop = (props: any) => {
  const componentTranslation = (componentName: string, data: any) => {
    switch (componentName) {
      case 'Link':
        return <WhiteLink href={data.ref} target={ data.external ? "_blank" : "_parent"}>{data.id}</WhiteLink>;
      case 'Image':
        return <img height="25px" width="225px" src={data.src} alt={data.alt}></img>;
      case 'Text':
        return <span>&#169; {new Date().getFullYear()} {parse(data.text)}</span>;
      default:
        console.log('Component Not Found');
        break;
    }
  };

  return (
    <Grid container className="desktopGrid">
      {props.data.map((item: any, index: number) => (
        <Grid item key={index} sx={{display: "flex", flexDirection: "row"}}>
          {item.items.map((itemData: any, index: number) => (
            <Paper className="itemWrapper" key={index}>
              {componentTranslation(itemData.type, itemData)}
              {item.items.length - 1 != index && (itemData.type == "Link") ? parse("&nbsp;&nbsp;|&nbsp;&nbsp;") : null}
            </Paper>

          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default FooterNavbarDesktop;
