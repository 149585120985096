import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import privacyPolicyPagesHtml from '../data/privacyPages.json';

// Component to render the privacy policy page based on the current pathname
// The HTML code is retrieved from the privacyPages.json file in the ../data directory
// Uses jsx because Termly html is not compatible with TypeScript
const PrivacyPolicyPages = ({ pathname, isMobile }) => {
  const [htmlObject, setHtmlObject] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);

    const newHtmlObject = privacyPolicyPagesHtml.pages.find(item => item.url === pathname);
    setHtmlObject(newHtmlObject);
  }, [pathname]);

  const memoizedHtmlObject = useMemo(() => htmlObject, [htmlObject]);

  const styles = {
    width: isMobile ? '80%' : '50%',
    padding: '50px 0px',
    margin: '0 auto'
  };

  if (!memoizedHtmlObject) {
    return <div>Page not found</div>;
  }

  return (
    <div style={styles}>
      {parse(memoizedHtmlObject.htmlCode)}
    </div>
  );
};

PrivacyPolicyPages.propTypes = {
  pathname: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default PrivacyPolicyPages;
