import parse from 'html-react-parser';
import ImageButton from './ImageButton';
import '../../styles/TextOverlay.scss';

const TextOverlay = (props: any) => {
  return (
    <div
      data-testid="header"
      style={{
        color: props.data.textColor,
        width:
          (props.data.textPosition == 'left' || props.data.textPosition == 'right') &&
          !props.isMobile
            ? '40%'
            : props.isMobile
            ? '80%'
            : '60%',
        textAlign: props.data.textAlign ? props.data.textAlign : 'center',
        padding:
          props.data.textPosition == 'left' || props.data.textPosition == 'right'
            ? '25px 75px'
            : props.data.textPadding && !props.isMobile
            ? props.data.textPadding
            : props.data.mobileTextPadding && props.isMobile
            ? props.data.mobileTextPadding
            : '25px 0px',
      }}
    >
      {props.data.title ? <h1>{parse(props.data.title)}</h1> : null}
      {props.data.mainText ? <p style={{ opacity: 0.75 }}>{parse(props.data.mainText)}</p> : null}
      {props.data.button ? (
        <ImageButton
          buttonColor={props.data.button.color}
          textColor={props.data.button.textColor}
          text={props.data.button.text}
          url={props.data.button.url}
        ></ImageButton>
      ) : null}
    </div>
  );
};

export default TextOverlay;
