import React from 'react';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { MainNavbar } from './components/MainNavbar/MainNavbar';
import { FooterNavbar } from './components/FooterNavbar/FooterNavbar';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';
import CmsData from './config/cmsEx.json';
import { MainTemplate } from './pages/MainTemplate';
import ScrollToTop from './utils/ScrollToTop';
import { bottomNavDesktopHeight, bottomNavMobileHeight, IsMobile } from './constants/const';

Sentry.init({
  dsn: 'https://1ec9d0f759534993bf70f0b3dac46168@o446702.ingest.sentry.io/6059378',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RoutesRender = (RoutesData: any, Key: number, isMobile: boolean) => {
  return (
    <Route
      key={Key}
      path={RoutesData.path}
      element={<MainTemplate componentData={RoutesData} isMobile={isMobile} />}
    />
  );
};

function App() {
  const isPageMobile = IsMobile();
  const [userConsent] = useCookies(['user_consent']);
  
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <MainNavbar data={CmsData.nav} isMobile={isPageMobile}></MainNavbar>
      <div style={{ paddingBottom: isPageMobile ? bottomNavMobileHeight : bottomNavDesktopHeight }}>
        <PrivacyPolicyModal
          privacyPolicyExists={userConsent.user_consent ? false : true}
          isMobile={isPageMobile}
        />
        <ScrollToTop>
          <SentryRoutes>
            {CmsData.pages.map((Routes, Index) => RoutesRender(Routes, Index, isPageMobile))}
            <Route path="*" element={<Navigate to="/" />} />
          </SentryRoutes>
        </ScrollToTop>
      </div>
      <FooterNavbar data={CmsData.footerNav} isMobile={isPageMobile}></FooterNavbar>
    </div>
  );
}

export default App;
