import {
  BackgroundImage,
  Text,
  DualComponent,
  GridList,
  Card,
  Forms,
  ContentEmbed
} from '../utils/importComponents';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const MainTemplate = (props: any) => {
  const componentTranslation = (componentName: string, data: any, isMobile: boolean) => {
    //Switch Case for rendering the correct component
    switch (componentName) {
      case 'BackgroundImage':
        return <BackgroundImage data={data} isMobile={isMobile}></BackgroundImage>;
      case 'Text':
        return <Text data={data} isMobile={isMobile}></Text>;
      case 'DualComponent':
        return <DualComponent data={data} isMobile={isMobile}></DualComponent>;
      case 'GridList':
        return <GridList data={data} isMobile={isMobile}></GridList>;
      case 'Card':
        return <Card data={data} isMobile={isMobile}></Card>;
      case 'Forms':
        return <Forms data={data} isMobile={isMobile}></Forms>;
      case 'ContentEmbed':
        return <ContentEmbed data={data} isMobile={isMobile}></ContentEmbed>;
      default:
        console.log('Component Not Found');
        break;
    }
  };
  //Main template to map out the components of the selected Route/Page
  return (
    <HelmetProvider context={{}}>
      <Helmet>
        <title>{props.componentData?.metatags?.title || "Bringing clean air to those who need it most | IQAir Foundation"}</title>
        {props.componentData?.metatags?.description && (<meta name="description" content={props.componentData.metatags.description} />) }
      </Helmet>
      <div>
        <br />
        {props.componentData.Components.map((data: any, index: number) => (
          <div
            key={index}
            style={{ backgroundColor: data.containerBackgroundColor || 'white' }}
          >
            <div
              style={{
                padding: props.isMobile
                  ? '0px'
                  : data.containerBackgroundColor && data.containerBackgroundColor != 'white'
                  ? '50px 0px'
                  : '20px 0px', //Removing padding for Mobile View
                width: props.isMobile || data.pageWidth == 'full' ? '100%' : '90%',
                margin: '0 auto',
              }}
            >
              {componentTranslation(data.type, data, props.isMobile)}
            </div>
          </div>
        ))}
      </div>
    </HelmetProvider>
  );
};
