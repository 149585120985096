import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const RadioButtonForm = (props: any) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ paddingTop: '15px' }}>
        {props.textField.title}
      </FormLabel>
      <RadioGroup
        row
        aria-label={props.textField.id}
        defaultValue={props.textField.defaultValue}
        name={props.textField.id}
      >
        {props.textField.itemSelection.map((items: any, index: number) => (
          <FormControlLabel
            key={index}
            value={items.value}
            control={<Radio />}
            onChange={(e) => props.changeState(e, props.textField.id)}
            label={items.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonForm;
