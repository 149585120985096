import { Grid } from '@mui/material';
import ImageButton from './ImageButton';
import parse from 'html-react-parser';
import '../../styles/DualComponent.scss';

const DualComponentText = (props: any) => {
  return (
    <Grid
      item={true}
      className="dualTextWrapper"
      order={props.data.textPosition == 'left' ? 1 : 2}
      xs={props.isMobile ? 12 : 6}
      sx={{
        minHeight:
          props.data.mobileBackgroundHeight && props.isMobile
            ? props.data.mobileBackgroundHeight
            : props.data.backgroundHeight && !props.isMobile
            ? props.data.backgroundHeight
            : '500px',
        backgroundColor:
          props.isMobile && props.data.mobileBackgroundColor
            ? props.data.mobileBackgroundColor
            : null,
        color: props.isMobile && props.data.textColorMobile ? props.data.textColorMobile : null,
      }}
    >
      <div
        style={{
          textAlign:
            props.isMobile && props.data.mobileTextAlign
              ? props.data.mobileTextAlign
              : props.data.textAlign,
          width: '80%',
        }}
      >
        {props.data.preText ? <p> {parse(props.data.preText)}</p> : null}
        {props.data.title ? <h1>{parse(props.data.title)}</h1> : null}
        {props.data.mainText ? <p> {parse(props.data.mainText)}</p> : null}
        {props.data.button ? (
          <ImageButton
            buttonColor={props.data.button.color}
            textColor={props.data.button.textColor}
            text={props.data.button.text}
            url={props.data.button.url}
          ></ImageButton>
        ) : null}
      </div>
    </Grid>
  );
};

export default DualComponentText;
