import { useLocation } from 'react-router';
import parse from 'html-react-parser';
import PrivacyPolicyPages from '../../components/PrivacyPolicyPages.jsx';
import { useEffect } from 'react';


interface Props {
  data: {
    isPrivacyPage?: boolean;
    mainText: string;
  };
  isMobile: boolean;
  textWidth?: string;
  customPadding?: string;
}

const ContentEmbed = (props: Props) => {
  const { pathname } = useLocation();
  const htmlString = props.data.mainText;

  //Because of SPA window needs to be fully reloaded for any embeds that might be synchronized with an external script
  const reloadPage = () => {
    if (window.localStorage) {
      if (!localStorage.getItem('firstLoad')) {
        localStorage['firstLoad'] = true;
        window.location.reload();
      } else localStorage.removeItem('firstLoad');
    }
  };

  useEffect(() => {
    reloadPage();
  }, []);

  return props.data.isPrivacyPage ? (
    <PrivacyPolicyPages pathname={pathname} isMobile={props.isMobile} />
  ) : (
    <div
    style={{
      width: props.isMobile ? '80%' : props.textWidth ? props.textWidth : '50%',
      padding: props.customPadding ? props.customPadding : '50px 0px',
      margin: '0 auto',
    }}>{parse(htmlString)}</div>
  );
};

export default ContentEmbed;
